/* eslint-disable react/jsx-no-literals */
import React from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { Container, ContentrArea } from '@ttstr/components';

const FormPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <article>
      <Helmet>
        <body className="form-page" />
      </Helmet>

      <div className="headerimage">
        <div className="headerimage-content text-right p-5 d-none">
          <h1>
            {'Das neue Album'}
            {/* <small className="d-block mb-3">Jetzt vorbestellen</small> */}
          </h1>
          <a href="/product/42476/gin" className="btn btn-primary btn-lg">
            {'Jetzt vorbestellen'}
          </a>
        </div>
      </div>

      <Container className="py-5">
        <ContentrArea id="main-content" />
      </Container>
    </article>
  );
};

export default React.memo(FormPage);
