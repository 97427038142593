import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Alert from 'reactstrap/lib/Alert';
import { Link } from 'react-router-dom';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import { receiveTickets } from '@ttstr/actions';
import { Container, ContentrArea, LoadingSpinner, Masonry, useIntl } from '@ttstr/components';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';

const TicketListing: React.FC = () => {
  const { t } = useTranslation();
  const { loading, tickets } = useShallowEqualSelector(mapStateToProps);
  const { receiveTickets } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    receiveTickets();
  }, [language]);

  return (
    <article>
      <Helmet>
        <title>{t(`TICKETLISTING.TITLE`)}</title>
        <body className="ticket-listing-page" />
      </Helmet>

      <div className="headerimage">
        <div className="headerimage-content text-right p-5 d-none">
          <h1>
            {'Das neue Album'}
            {/* <small className="d-block mb-3">Jetzt vorbestellen</small> */}
          </h1>
          <a href="/product/42476/gin" className="btn btn-primary btn-lg">
            {'Jetzt vorbestellen'}
          </a>
        </div>
      </div>

      <h1 className="text-center my-4">{t(`TICKETLISTING.TITLE`)}</h1>

      <Container className="listing-filter-buttons-container">
        <h5 className="my-1">FILTER:</h5>
        <Row className="mb-0 d-flex justify-items-center justify-content-center listing-buttons-container">
          <Col className="mt-3">
            <Link to="/tickets" className="btn btn-lg btn-block btn-outline-dark btn-tickets h-100">
              {'ALLE TERMINE'}
            </Link>
          </Col>
          <Col className="mt-3">
            <Link to="/tickets?tagId=7912" className="btn btn-lg btn-block btn-outline-dark btn-tickets h-100">
              {'Hände hoch, Hose runter'}
            </Link>
          </Col>
          <Col className="mt-3">
            <Link to="/tickets?tagId=7911" className="btn btn-lg btn-block btn-outline-dark btn-tickets h-100">
              {'Festivals 2024'}
            </Link>
          </Col>
        </Row>
      </Container>

      <Container className="list-page py-5 ticket-listing">
        <Container>
          <ContentrArea id="before-listing" />
        </Container>

        {loading ? ( // if loading
          <LoadingSpinner label={t(`LOADING.TICKETS`)} />
        ) : tickets.length === 0 ? ( // if no tickets
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show tickets
          <Masonry id="ticket-listing" products={tickets} type="list" showCity showLocation />
        )}
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { tickets, loading } = state.Tickets;
  return {
    tickets,
    loading,
  };
};

const mapDispatchToProps = {
  receiveTickets,
};

export default React.memo(TicketListing);
