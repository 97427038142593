import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Alert from 'reactstrap/lib/Alert';

import { receiveProducts } from '@ttstr/actions';
import { Container, LoadingSpinner, Maki, Masonry, useIntl, ContentrArea } from '@ttstr/components';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';
import { Article } from '@ttstr/api';

type Props = {
  /**
   * productFilter can be used if you want a Route to a dedicated page for special merch/products.
   *
   * Example:
   * You want a page only with merch that has the tag id 1234.
   * The function for productFilter would then be
   *  p => p.tag_ids.includes(1234)
   *
   * Then create a route in App.tsx with
   * <Route exact path="/specialmerch" render={()=> <ProductListing productFilter={p => p.tag_ids.includes(1234)} />} />
   */
  productFilter?: (p: Article) => any;
};

const ProductListing: React.FC<Props> = ({ productFilter = (p) => p }) => {
  const { t } = useTranslation();
  const { loading, products } = useShallowEqualSelector(mapStateToProps);
  const { receiveProducts } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    const abortController = new AbortController();
    receiveProducts(undefined, { signal: abortController.signal });
    return () => abortController.abort();
  }, [language]);

  return (
    // <article className="py-5 product-listing">
    //   <Container className="list-page">
    //     <Helmet>
    //       <title>{t(`PRODUCTS.TITLE`)}</title>
    //     </Helmet>

    //     <Masonry
    //       id="product-listing"
    //       rowClassName="card-columns"
    //       columnClassName=""
    //       products={products}
    //       filters="off"
    //       showSubtitle
    //     />
    //     {loading && <LoadingSpinner label={t(`LOADING.PRODUCTS`)} />}
    //   </Container>
    // </article>

    <article>
      <Helmet>
        <title>{t(`PRODUCTS.TITLE`)}</title>
        <body className="product-listing-page inverse-navbar" />
      </Helmet>

      <div className="headerimage">
        <div className="headerimage-content text-right p-5 d-none">
          <h1>
            {'Das neue Album'}
            {/* <small className="d-block mb-3">Jetzt vorbestellen</small> */}
          </h1>
          <a href="/product/42476/gin" className="btn btn-primary btn-lg">
            {'Jetzt vorbestellen'}
          </a>
        </div>
      </div>

      <Container className="list-page py-5">
        <h1 className="text-center my-5 superhead d-none">Merch</h1>

        {loading ? ( // if loading
          <LoadingSpinner label={t(`LOADING.PRODUCTS`)} />
        ) : products.length === 0 ? ( // if no products
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show products
          <Masonry
            id="product-listing"
            products={products.filter(productFilter)}
            type={Maki}
            filters="off"
            showSubtitle
          />
        )}
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { products, loading } = state.Products;
  return {
    products,
    loading,
  };
};

const mapDispatchToProps = {
  receiveProducts,
};

export default React.memo(ProductListing);
